import { MatButtonModule } from '@angular/material/button';
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../environments/environment";
import { MessageParagraphsPipe } from "../../pipes/messageParagraphs.pipe";

@Component({
  standalone: true,
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  imports: [MatButtonModule, MessageParagraphsPipe],
})
export class LoginComponent implements OnInit, OnChanges {
  @Input() isOffset: boolean = false;
  @Input() prompt: boolean = false;
  env = environment;
  redirect = false;
  href: string;

  constructor(public authService: AuthService) { }

  ngOnChanges() {
    try {
      this.login();
    } catch { }
  }

  ngOnInit(): void {
    if (environment.loginConfig.popout) {
      this.href = environment.loginConfig.popout;
      this.redirect = false;
    } else if (environment.loginConfig.redirect && this.prompt) {
      this.redirect = true;
      this.href = `${environment.loginConfig.redirect
        }&return=${encodeURIComponent(window.location.href)}`;
    } else {
      window.location.href = `${environment.loginConfig.redirect
        }&return=${encodeURIComponent(window.location.href)}`;
    }
  }

  login() {
    return this.authService.getUser();
  }
}
