import { DollarsPipe } from "./../../../pipes/dollars.pipe";
import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { StudentResource } from "../../../models/student-resource.model";
import { StudentResourceService } from "../../../services/student-resource.service";
import { ErrorService } from "../../../services/error.service";
import { StudentAccounts } from "../../../models/student-transform.model";
import { PopoverLinkComponent } from "../../popover-link/popover-link.component";
import { DynamicTextsComponent } from "../../dynamic-texts/dynamic-texts.component";
import { MessageParagraphsPipe } from "../../../pipes/messageParagraphs.pipe";

@Component({
  selector: "gsu-bill-refund",
  templateUrl: "bill-refund.component.html",
  standalone: true,
  styleUrls: ["bill-refund.component.scss"],
  imports: [
    PopoverLinkComponent,
    DynamicTextsComponent,
    DollarsPipe,
    MessageParagraphsPipe,
  ],
})
export class BillRefundComponent implements OnChanges {
  private className = "BillRefundComponent";
  @Input() studentAccounts: StudentAccounts;
  public refundOptionsUri?: StudentResource;
  public refundAuthorizationUri?: StudentResource;
  public billRefundData: { date: string };

  constructor(
    private studentResourceService: StudentResourceService,
    private errorService: ErrorService
  ) {
    this.refundOptionsUri = this.studentResourceService.getResource(
      "my-bill",
      "refund-options"
    );
    this.refundAuthorizationUri = this.studentResourceService.getResource(
      "my-bill",
      "refund-authorization"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fn: string = "?>" + this.className + "#ngOnChanges";
    try {
      if (
        changes.studentAccounts.currentValue !== undefined &&
        this.studentAccounts &&
        this.studentAccounts.refundLastAmount &&
        this.studentAccounts.refundLastDate
      ) {
        this.billRefundData = { date: this.studentAccounts.refundLastDate };
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      e.message = `${fn}/CATCH: Error in OnChanges -- ${e.message}`;
      console.error(e);
    }
  }
}
